<template>
  <div>
    <div class="facilityBox">
      <el-tabs @tab-click="cmptChange" v-model="activePage" class="barWrap">
        <el-tab-pane label="服务商" name="index">
          <div class="goodsOwnerManage">
            <div>
              <div class="topbtns">
                <el-button type="success" size="medium" @click="flag.showAddDialog = !flag.showAddDialog"
                  icon="el-icon-circle-plus-outline">添加</el-button>
                <el-button type="danger" size="medium" @click="batchDelete" icon="el-icon-remove-outline">删除</el-button>
              </div>
              <div class="searchBox">
                <el-form class="searchForm" :model="searchForm" ref="searchForm" label-width="90px">
                  <el-form-item label="企业名称" prop="EnterpriseFullName">
                    <el-input v-model="searchForm.EnterpriseFullName" placeholder="请输入企业名称"></el-input>
                  </el-form-item>
                  <el-form-item label="签订日期" prop="field3" label-width="110px">
                    <el-date-picker v-model="searchForm.dateTime" type="daterange" value-format="yyyy-MM-dd"
                      range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label-width="10px">
                    <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
                    <el-button type="primary" size="medium" icon="el-icon-delete" @click="resetForm()">清空</el-button>
                  </el-form-item>
                </el-form>
              </div>
              <el-table :data="tableData" @selection-change="tableSelectionChange"
                :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading">
                <el-table-column type="selection" align="center" width="50" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" type="index" prop="UserID" label="序号" width="50" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseFullName" label="企业名称"
                  show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码"
                  show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="CreatedTime" label="签订日期" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <el-button type="primary" size="small" @click="goFacility(scope.row)"
                      icon="el-icon-document">查看费率</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination background class="pagination" @current-change="handleCurrentChange"
                :current-page.sync="pagination.page" :page-size="pagination.pagesize"
                layout="total, prev, pager, next, jumper" :total="pagination.total"></el-pagination>
            </div>
            <!-- 添加服务商 -->
            <el-dialog class="addDialog" :visible.sync="flag.showAddDialog" width="40%">
              <span slot="title" class="dialog-title">
                请选择合作伙伴
              </span>
              <div class="body">
                <el-row class="demo-autocomplete">
                  <el-col>
                    <el-autocomplete class="inline-input" v-model="partner" :fetch-suggestions="querySearch"
                      @select="choosePartner" placeholder="输入对方【邀请码】，添加为合作伙伴。【邀请码】在每个企业首页，右上角的个人中心里查看。"
                      @keyup.enter.native="bindSearch" @input="partner = partner.replace(/[^\d]/g, '')">
                      <el-button slot="append" icon="el-icon-search" @click="bindSearch()"></el-button>
                    </el-autocomplete>
                  </el-col>
                </el-row>
                <div style="margin-top:10px;">
                  <el-table :data="searchData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }">
                    <el-table-column align="center" width="55">
                      <template slot-scope="scope">
                        <el-radio v-model="ChineseRadio" :label="scope.row.index"></el-radio>
                      </template>
                    </el-table-column>
                    <el-table-column label="企业名称" align="center" prop="EnterpriseFullName" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="InvoiceType" align="center" label="发票类型" show-overflow-tooltip>
                    </el-table-column>
                    <!-- <el-table-column prop="Phone" align="center" label="联系方式" show-overflow-tooltip></el-table-column> -->
                    <el-table-column prop="EnterpriseAddress" align="center" label="企业住址" show-overflow-tooltip>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <div slot="footer">
                <el-button size="medium" @click="flag.showAddDialog = !flag.showAddDialog">取消</el-button>
                <el-button :disabled="searchData.length > 0 ? false : true" type="primary" size="medium" @click="add">确认
                </el-button>
              </div>
            </el-dialog>
            <!-- 查看费率 -->
            <el-dialog class="addDialog" :visible.sync="flag.isExchange" width="80%">
              <span slot="title" class="dialog-title">
                服务商信息
              </span>
              <el-alert style="margin-bottom:10px;" type="warning" :closable="false"
                title="温馨提示：请和服务商确认发票对应费率，确认后，即按双方约定结算。">
              </el-alert>
              <div class="dialog_box">
                <strong>企业名称：</strong><u> {{ exchangeData.EnterpriseFullName }}</u>
                <br />
                <strong>信用代码：</strong> <u> {{ exchangeData.EnterpriseCreditCode }}</u>
                <br />
                <!-- <strong>法人姓名：</strong>  {{exchangeData.LegalPersonIName}}
              <br />
              <strong>法人电话：</strong> {{exchangeData.LegalPersonlPhone}}
              <br /> -->
                <div style="margin:10px 0;">
                  <el-table :data="rateData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
                    max-height="500px" v-loading="loading">
                    <el-table-column align="center" label="发票-业务-场景">
                      <template slot-scope="scope">
                        <span v-if="scope.row.TaskSceneName">
                          {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} / {{
                            scope.row.TaskSceneName }}</span>
                        <span v-else>
                          {{ scope.row.InvoiceTypeName }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" label="费率" show-overflow-tooltip width="200">
                      <template slot-scope="scope">
                        <span>{{ scope.row.Rate ? scope.row.Rate + '%' : scope.row.Rate }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" label="技术服务费费率" show-overflow-tooltip width="200">
                      <template slot-scope="scope">
                        <span>{{ scope.row.TecRate ? scope.row.TecRate + '%' : scope.row.TecRate }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" label="合作状态" show-overflow-tooltip width="200">
                      <template slot-scope="scope">
                        <el-tag :type="scope.row.StatusCode == 0 ? 'danger' : 'success'">{{ scope.row.StatusName
                        }}</el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" label="开通状态" show-overflow-tooltip width="200">
                      <template slot-scope="scope">
                        <el-tag :type="scope.row.OpenCode == 0 ? 'danger' : 'success'">{{ scope.row.OpenName }}</el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="200">
                      <template slot-scope="scope">
                        <el-button type="primary" size="small" @click="toDetail(scope.row)"
                          :disabled="!scope.row.RateID">查看历史记录</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <!-- <span slot="footer" class="dialog-footer">
                <el-button type="primary" size="medium" @click="supplierClick()" icon="el-icon-edit"
                  :disabled="exchangeData.SignStatus == 1">确认签约</el-button>
              </span> -->
            </el-dialog>
            <!-- 查看费率历史记录 -->
            <el-dialog title="历史记录" append-to-body :visible.sync="rateRecordDialog" width="50%" v-if="rateRecordDialog"
              class="rate-dialog">
              <div style="text-align: center;font-weight: bold;margin-bottom: 10px;">发票-业务-场景： <span
                  v-if="currentItem.TaskSceneName">
                  {{ currentItem.InvoiceTypeName }} / {{ currentItem.TaskTypeName }} / {{
                    currentItem.TaskSceneName
                  }}</span>
                <span v-else>
                  {{ currentItem.InvoiceTypeName }}</span>
              </div>
              <el-table :data="rateDataRecord" :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
                v-loading="loading" max-height="450px">
                <el-table-column type="index" align="center" label="序号" width="50" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Rate" label="费率" show-overflow-tooltip>
                  <template slot-scope="scope">{{ scope.row.Rate ? scope.row.Rate + '%' : '' }}</template>
                </el-table-column>
                <el-table-column align="center" prop="TecRate" label="技术服务费费率" show-overflow-tooltip>
                  <template slot-scope="scope">{{ scope.row.TecRate ? scope.row.TecRate + '%' : '' }}</template>
                </el-table-column>
                <el-table-column align="center" prop="CteatedTime" label="变更时间" show-overflow-tooltip>
                </el-table-column>
              </el-table>
              <div style="margin-top:10px">
                <el-pagination background class="pagination" @current-change="handleCurrentChangeRecord"
                  :current-page.sync="paginationRecord.page" :page-size="paginationRecord.pagesize"
                  layout="total, prev, pager, next, jumper" :total="paginationRecord.total"></el-pagination>
              </div>
            </el-dialog>
          </div>
        </el-tab-pane>
        <el-tab-pane label="代理商" name="agency">
          <el-table :data="agencyTable" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" width="200"
            v-loading="loading">
            <el-table-column align="center" type="index" prop="UserID" label="序号" width="50" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="LegalPersonIName" label="姓名" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="LegalPersonIIDCard" label="身份证号" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="LegalPersonlPhone" label="手机号" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="SignDatetime" label="签订日期" show-overflow-tooltip></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import {
  getSecPriseList,
  addPartner,
  removeSupplier,
  GetEnterPriseList,
  updateSupplier,
  getConsignor,
  GetEnterAgentInfo
} from "@/api/goodsOwner/serviceProviderCooperation/index";
import { GetPartners, AddPartners, DelPartners, GetOwnerPartnersRate, GetRateRecords } from '@/api/newRate'
import { _getUserId } from "@/utils/storage";
export default {
  data() {
    return {
      activePage: 'index',
      //服务商列表
      partnerList: [],
      //选择的合作伙伴名称
      partner: "",
      //选择的合作伙伴
      partnerObj: {},
      // 控制弹窗显示
      flag: {
        // 是否显示添加合作伙伴
        showAddDialog: false,
        // 是否显示查看费率信息
        isExchange: false,
      },
      //分页控件相关参数
      pagination: {
        //当前所处的页码
        page: 1,
        //每次请求的数量
        pagesize: 10,
        //总条数
        total: 0,
      },
      // 搜索框
      searchForm: {
        EnterpriseFullName: "", //企业名称
        dateTime: ["", ""], //合作起始日期
        InvoiceType: "", //票据类型
        LegalPersonlPhone: ''
      },
      //表格数据
      tableData: [],
      //表格中选中的items
      selectedTableItemList: [],
      // 服务商信息
      exchangeData: {},
      // 搜索到的服务商
      searchData: [],
      // 选中的服务商
      ChineseRadio: true,
      // 表格loading
      loading: false,
      // 费率数据
      rateData: [],
      // 代理商数据
      agencyTable: [],
      currentItem: '',
      rateDataRecord: [],
      rateRecordDialog: false,
      paginationRecord: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
    };
  },
  watch: {
    // 监听添加服务商弹窗 并 初始化数据
    'flag.showAddDialog'(val) {
      if (val == false) {
        this.partner = '';
        this.searchData = []
      }
    }
  },
  methods: {
    // tab切换
    cmptChange(e) {
      this.loading = true
      if (e.index == 0) {
        // 获取服务商的货主列表
        this.getSecPriseList();
      } else {
        GetEnterAgentInfo({}).then(res => {
          this.agencyTable = res.enterAgent
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    // 搜索服务商
    searchInput(e) {
      if (e.length == 0) {
        //搜索前初始化page
        this.searchData = [];
        this.partnerObj = {};
      }
    },
    // 搜索
    bindSearch() {
      if (this.partner) {
        this.searchData = [];
        this.partnerObj = {};
        GetEnterPriseList({ Phone: this.partner }).then((res) => {
          if (res.equipments.length == 0) return this.$message.error('未找到该服务商，请确认邀请码是否正确。');
          this.searchData = res.equipments;
          this.partnerObj = res.equipments[0];
        });
      } else {
        this.$message.error('请输入服务商注册手机号');
      }
    },
    // 确认签约
    supplierClick() {
      const loading = this.$loading({
        lock: true,
        text: '签约中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      updateSupplier({ ID: this.exchangeData.UserID }).then((res) => {
        this.flag.isExchange = false;
        this.getSecPriseList();
        loading.close()
      }).catch(() => {
        loading.close()
      });
    },
    // 查看费率
    goFacility(item) {
      this.exchangeData = item;
      GetOwnerPartnersRate({ partnerUserID: item.UserID, userID: parseInt(_getUserId()) / 5 }).then((res) => {
        if (res.data.length == 0) {
          this.$message.error("该服务商暂未设置费率'");
          return;
        } else {
          let list = [];
          res.data.forEach(item => {
            item.Rate = (item.Rate == 0 || item.Rate == null) ? '' : item.Rate
            item.TecRate = (item.TecRate == 0 || item.TecRate == null) ? '' : item.TecRate
            list.push(item)
          })
          this.rateData = list;
          this.flag.isExchange = true;
        }
      });
    },
    // 查看历史记录
    toDetail(item) {
      this.currentItem = item
      this.getRateChangeRecord()
    },
    //分页控件页码change事件回调
    handleCurrentChangeRecord(e) {
      this.paginationRecord.page = e;
      this.getRateChangeRecord();
    },

    // 获取历史费率
    getRateChangeRecord() {
      this.loading = true;
      let data = {
        pageIndex: this.paginationRecord.page,
        PageSize: this.paginationRecord.pagesize,
        rateID: this.currentItem.RateID
      };
      GetRateRecords(data)
        .then((res) => {
          this.rateDataRecord = res.data.DataList || [];
          this.paginationRecord.total = Number(res.data.TotalCount);
          this.rateRecordDialog = true
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //搜索框选中或者绑定的数据发生改变时执行
    querySearch(queryString, cb) {
      let partnerList = this.partnerList;
      let results = queryString
        ? partnerList.filter(this._createFilter(queryString))
        : partnerList;
      cb(results);
    },
    _createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    choosePartner(e) {
      this.partnerObj = e;
      this.partner = e.value;
    },
    //tbale选中改变
    tableSelectionChange(e) {
      this.selectedTableItemList = e;
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.getSecPriseList();
    },
    //搜索
    search() {
      this.pagination.page = 1;
      this.getSecPriseList();
    },
    //清空
    resetForm() {
      this.searchForm = {
        EnterpriseFullName: "",
        InvoiceType: "",
        dateTime: ["", ""],
        LegalPersonlPhone: ''
      };
      this.search()
    },
    //新增
    add() {
      const loading = this.$loading({
        lock: true,
        text: '添加中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      AddPartners({ userID: parseInt(_getUserId()) / 5, partnerUserID: this.partnerObj.UserID }).then((res) => {
        this.$message.success("添加成功")
        this.flag.showAddDialog = false;
        this.search();
      }).finally(() => {
        loading.close()
      });
    },
    //批量删除
    batchDelete() {
      if (this.selectedTableItemList.length === 0) {
        this.$message.error("请在表格中勾选要删除的数据");
        return;
      }
      let params = [];
      this.selectedTableItemList.forEach((item) => {
        params.push(item.UserID);
      });
      this.$confirm("此操作将删除该批数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          DelPartners({ partnerUserIDList: params, userID: parseInt(_getUserId()) / 5 }).then((res) => {
            this.$message.success("删除成功")
            this.search()
          });
        })
        .catch(() => { });
    },
    //获取服务商的货主列表
    getSecPriseList() {
      this.loading = true;
      let params = {
        enterpriseFullName: this.searchForm.EnterpriseFullName,
        createdTimeStart: (this.searchForm.dateTime && this.searchForm.dateTime[0]) ? this.searchForm.dateTime[0] + ' 00:00:00' : '',
        createdTimeEnd: (this.searchForm.dateTime && this.searchForm.dateTime[1]) ? this.searchForm.dateTime[1] + ' 23:59:59' : '',
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        userID: parseInt(_getUserId()) / 5
      }
      GetPartners(params).then(res => {
        this.tableData = res.data.DataList;
        this.pagination.total = res.data.TotalCount;
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  created() {
    // 获取服务商的货主列表
    this.getSecPriseList();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/style/variable.scss";

.title {
  color: $primary;
  position: relative;
  text-indent: 15px;
  font-size: 14px;
  border-bottom: 1px solid $borderGray;
  padding: 0px;
  margin-bottom: 10px;
  display: flex;
  flex: 1;
  justify-content: space-between;

  span {
    line-height: 36px;

    &::after {
      content: '';
      position: absolute;
      top: 44%;
      left: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 14px;
      border-radius: 5px;
      background: $primary;
    }
  }
}

.goodsOwnerManage {
  width: 100%;
  padding: 0px;

  .facilityBox {

    // margin-top: 14px;
    // max-height:84.5vh;
    .el-table {
      .has-gutter {
        th {
          background-color: azure;
        }
      }
    }
  }

  .el-form-item {
    margin-bottom: 14px;
  }

  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }

  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }

  .addForm {
    display: flex;
    flex-wrap: wrap;

    .el-item {
      width: 50%;
    }

    .el-date-editor {
      width: 100% !important;
    }
  }

  .el-pagination button {
    border: none;
  }

  .pagination {
    margin-top: 10px;
  }

  .addDialog {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .dialog_box {
      font-size: 14px;
      line-height: 26px;
      width: 100%;
      background: #f8f8f8;
      padding: 20px 1%;
      box-sizing: border-box;
      color: #666;
      border-radius: 5px;
    }

    .dialog-title {
      font-size: 15px;
      // color: $primary;
      // font-weight: 600;
    }

    .body {
      .el-autocomplete {
        width: 100%;
      }

      .choose {
        margin-top: 50px;
        display: flex;
        justify-content: center;
      }
    }
  }

}
</style>